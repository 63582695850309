@media only screen and (min-width: 1000px) {
  .homeImage {
    height: 700px;
    width: 100%;
    background-image: url("../../images/coverPhoto.jpg");
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;

    h1 {
      font-family: 'Rozha One';
      font-size: 60px!important;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 6px;
      max-width: 50%;
      margin: auto;
      color: #fff;
      padding-top: 24%;
      text-align: center;
    }
  }

  #homeBottomImage {
    height: 700px;
  }

  .homeContent {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }

  #homeNames {
    padding: 100px 0px 50px;
    text-align: center;
    letter-spacing: 6px;
  }

  #ellen, #rob {
    font-family: 'Rozha One', serif;
    font-size: 54px;
    margin-top: 27px;
    margin-bottom: 27px;
  }

  #and {
    font-size: 18px;
    text-transform: uppercase;
  }

  #dateCity {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 150px;
  }

  #date {
    font-family: 'Rozha One', sarif;
    font-size: 43px;
  }

  #city {
    font-size: 17px;
    text-transform: uppercase;
    margin: 20px 0px;
  }

  #homeBottomImage {
    height: 700px;
    width: 100%;
    background-image: url("../../images/footerPhoto.jpg");
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.1);
    background-position: 0 60%;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  #mobileImage, #mobileNames {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .homeImage {
    display: none;
  }

  #homeNames {
    display: none;
  }

  #mobileImage {
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    height: auto;
    width: 100%;
  }

  #mobileNames {
    font-weight: bold;
    text-align: center;
    font-size: 20pt;
    margin-top: 10px;
  }

  #dateCity {
    text-align: center;
    margin-bottom: 20px;
  }
}

.button {
  border: 2px solid #e7e7e7;
  background-color: white;
  color: black;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.button:hover {
  background-color: #e7e7e7;
}
