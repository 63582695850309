@media (min-width: 1000px) {
  .datePlace {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 16px!important;
    font-weight: 500;
    letter-spacing: 1.2px;
  }

  .date {
    margin-right: 25px;
    float: left;
    margin-bottom: 0;
  }

  .city {
    margin-bottom: 0;
  }

  .names {
    font-size: 36px;
    font-family: 'Rozha One',serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 6px;
    font-weight: bolder!important;

    #er {
      text-decoration: none;
    }
  }
}

@media (max-width: 1000px) {
  .datePlace {
    display: none;
  }

  .names {
    text-align: center;
    padding-top: 10px;
  }
}
