@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');

* {
  font-family: 'Nanum Myeongjo', serif;
  color: #A4B1B8;
}

body {
  margin: 0;
  font-family: 'Nanum Myeongjo', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;
  background-color: white;
  z-index: 2;
}

.navBar {
  font-size: 14px!important;
  font-weight: 700;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  margin-right: auto;
  margin-left: auto;
}

.navBar a {
  color: #A4B1B8;
  font-weight: 500!important;
  padding-right: 15px;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  #content {
    padding: 20px;
  }
}
